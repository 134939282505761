import "./src/styles/global.css"
import React from 'react';
import CookieConsentBanner from './src/components/cookie-consent-banner';

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <CookieConsentBanner />
  </>
);

export const onRouteUpdate = ({ location }) => {
    if (typeof window.gtag === `function`) {
      window.gtag('config', 'G-B4J2PZY5QX', {
        page_path: location.pathname,
      });
    }
  };