import { enableAnonymousGA, enableGA } from '../helpers/gtag';
import React, { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

const CookieConsentBanner = () => {
  const [gaInitialized, setGaInitialized] = useState(false);



  const handleAccept = () => {
    enableGA(setGaInitialized);
    Cookies.set('gatsby-site-cookie-consent', 'true', { path: '/' });
  };

  const handleDecline = () => {
    Cookies.set('gatsby-site-cookie-consent', 'false', { path: '/' });
  };

  useEffect(() => {
    const consent = Cookies.get('gatsby-site-cookie-consent');
    if (consent === 'true' && !gaInitialized) {
      enableGA(setGaInitialized);
    } else if (!consent) {
      enableAnonymousGA();
    }
  }, [gaInitialized]);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-site-cookie-consent"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      style={{ background: '#1f2937' }}
      buttonStyle={{
        backgroundColor: '#4f46e5',
        color: '#fff',
        borderRadius: '8px',
        padding: '10px 20px',
      }}
      declineButtonStyle={{
        backgroundColor: '#6b7280',
        color: '#fff',
        borderRadius: '8px',
        padding: '10px 20px',
        marginLeft: '10px',
      }}
    >
      <p className="text-sm text-white">
        We use cookies to enhance your experience. By accepting, you agree to
        our{' '}
        <a href="/privacy-policy" className="underline text-indigo-400">
          privacy policy
        </a>.
      </p>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
