/**
 * Tracks a custom event using Google Analytics 4 via gatsby-plugin-google-gtag.
 * @param {string} eventName - The name of the event (e.g., 'button_click').
 * @param {Object} eventParams - The parameters associated with the event.
 */

declare global {
interface Window {
    gtag?: (...args: any[]) => void;
  }
}

export const trackCustomEvent = (eventName: string, eventParams: object = {}) => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", eventName, eventParams);
    } else {
      console.warn("Gtag not loaded yet.");
    }
  };


// Initialize GA with anonymous tracking
export const enableAnonymousGA = () => {
  const gtag_key = process.env.GATSBY_GTAG_TRACKING_ID;
    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('config', gtag_key, { anonymize_ip: true, storage: 'none' });
    }
};

    // Enable full tracking on consent
export const enableGA = (setGaInitialized: (data: boolean) => void ) => {
    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
        });
    }
    setGaInitialized(true);
};
  